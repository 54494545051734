import React from "react";
import styled from "@emotion/styled/macro";

import FlagBadge from "./flagBadge";

const FlagBadges = ({ className, flagData }) => {
  return (
    <div className={className}>
      {flagData.map((flag) => (
        <FlagBadge
          key={flag.text}
          state={flag.state}
          text={flag.text}
          fullText={flag.fullText}
        />
      ))}
    </div>
  );
};
const StyledFlagBadges = styled(FlagBadges)`
  display: flex;
`;
export default StyledFlagBadges;
