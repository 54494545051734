import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import ScorecardWholeCompanyDataRow from "src/design-system/components/scorecard/scorecard-wholecompany-data-row/scorecard-wholecompany-data-row";
import ScorecardControl from "src/design-system/components/scorecard/scorecard-control/scorecard-control";

const ScorecardSurveyRow = ({ className, data, updateSurveyRow }) => {
  const [rowData, setRowData] = useState(data);

  const selectHandler = (e) => {
    console.log("Selection CHanged", e.target.checked);
    const updatedRow = { ...rowData, isSelected: e.target.checked };
    setRowData(updatedRow);
    updateSurveyRow(updatedRow);
  };
  return (
    <div className={className}>
      <div className="control-wrap">
        <ScorecardWholeCompanyDataRow data={rowData} />
        <ScorecardControl
          onSelection={selectHandler}
          isSelected={rowData.isSelected}
        />
      </div>
    </div>
  );
};

const StyledScorecardSurveyRow = styled(ScorecardSurveyRow)`
  .control-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
  }
`;

export default StyledScorecardSurveyRow;
