import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

import SurveyList from "src/design-system/components/survey-list/survey-list";
import ScorecardSurveyHeader from "src/design-system/components/scorecard/scorecard-survey-header/scorecard-survey-header";
import ScorecardSurveyRow from "src/design-system/components/scorecard/scorecard-survey-row/scorecard-survey-row";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import LoadingSpinner from "src/design-system/atoms/loading-spinner";
import Button from "src/design-system/atoms/button";
import Status from "src/design-system/atoms/status";

const ScorecardSurveyList = ({ className }) => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [pageKey, setPageKey] = useState(null);
  const [hasMoreSurveys, setHasMoreSurveys] = useState(false);

  const surveyLimit = 100;

  const getApiData = async () => {
    setIsLoading(true);
    let atLimit = false;
    let tmpSurveys = [];
    let tmpPageKey = pageKey;
    while (!atLimit) {
      const params = { limit: surveyLimit, unreviewed_only: false };
      if (tmpPageKey) {
        params.last_evaluated_key = JSON.stringify(tmpPageKey);
      }
      await API.get("circulytics-surveys", "/", {
        queryStringParameters: params,
      }).then((res) => {
        tmpSurveys = tmpSurveys.concat(res.surveys);
        tmpPageKey = res.last_evaluated_key;
        atLimit = tmpSurveys.length >= surveyLimit || tmpPageKey === null;
      });
    }
    setSurveys([...tmpSurveys]);
    setPageKey(tmpPageKey);
    setHasMoreSurveys(tmpPageKey === null);
    setIsLoading(false);
  };
  useEffect(() => {
    getApiData();
  }, []);

  const loadMore = () => {
    if (pageKey) {
      getApiData();
    }
  };
  const triggerScorecardsAPI = () => {
    setIsRunning(true);
    const selectedSurveys = surveys
      .filter((survey) => {
        return survey.isSelected;
      })
      .map((survey) => {
        return survey.responseId;
      });

    if (selectedSurveys.length > 0) {
      API.post("circulytics-scorecards-generate", "/", {
        body: { surveys: selectedSurveys },
      })
        .then((res) => {
          setStatus({
            type: "success",
            message: "Scorecard service has been triggered",
          });
          setIsRunning(false);
          setSurveys([]);
          getApiData();
        })
        .catch((err) => {
          const errMessage =
            err.response.data.message ?? err.response.statusText;
          setStatus({ type: "error", message: errMessage });
          setIsRunning(false);
        });
    } else {
      setStatus({
        type: "error",
        message: "You have not selected any surveys",
      });
      setIsRunning(false);
    }
  };

  const updateDataHandle = (updatedRow, rowIndex) => {
    setSurveys([
      ...surveys.slice(0, rowIndex),
      updatedRow,
      ...surveys.slice(rowIndex + 1),
    ]);
  };
  const triggerScorecardsHandler = () => {
    triggerScorecardsAPI();
  };
  return (
    <LoadingRow isLoading={isRunning}>
      <div className={className}>
        <SurveyList
          surveys={surveys}
          isLoading={isLoading}
          hasMoreSurveys={hasMoreSurveys}
          SurveyHeader={ScorecardSurveyHeader}
          SurveyItem={ScorecardSurveyRow}
          updateDataHandle={updateDataHandle}
          loadMoreHandle={loadMore}
        />
        <div className="survey-control">
          <Status status={status} />
          <Button
            onClick={triggerScorecardsHandler}
            color={vars.barrowWightGrey}
            backgroundColor={vars.learningGreen}
          >
            {isRunning ? <LoadingSpinner /> : <span>Generate scorecards</span>}
          </Button>
        </div>
      </div>
    </LoadingRow>
  );
};

const StyledScorecardSurveyList = styled(ScorecardSurveyList)`
  .survey-control {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 350px;
    margin-top: 1rem;
    ${Button} {
      padding: 1rem;
    }
  }
`;

export default StyledScorecardSurveyList;
