import * as React from "react";
import styled from "@emotion/styled/macro";

const Checkbox = ({ dataTestId, className, labelText, onSelection, isSelected }) => {
  return (
    <label data-testid={dataTestId} className={className}>
      <input data-testid={`${dataTestId}-input`} type="checkbox" onChange={onSelection} checked={isSelected} />
      {labelText}
    </label>
  );
};

const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  user-select: none;

  input {
    margin-right: 0.5rem;
  }
`;

export default StyledCheckbox;
