import React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

const FlagBadge = ({ className, state, text, fullText }) => {
  const stateMapping = {
    true: "Approved",
    false: "Unapproved",
    null: "Unreviewed",
  };
  const friendlyState = stateMapping[state];
  if (state === true) {
    return (
      <div className={className} title={fullText + " - " + friendlyState}>
        <div className="badge approved">{text}</div>
      </div>
    );
  } else if (state === false) {
    return (
      <div className={className} title={fullText + " - " + friendlyState}>
        <div className="badge unapproved">{text}</div>
      </div>
    );
  } else {
    return (
      <div className={className} title={fullText + " - " + friendlyState}>
        <div className="badge unreviewed">{text}</div>
      </div>
    );
  }
};
const StyledFlagBadge = styled(FlagBadge)`
  padding: 1px;
  .badge {
    color: white;
    width: 30px;
    text-align: center;
    &:hover {
      cursor: help;
    }
  }
  .approved {
    background-color: ${vars.learningGreen};
  }
  .unapproved {
    background-color: ${vars.circulyticsRed};
  }
  .unreviewed {
    background-color: grey;
  }
`;
export default StyledFlagBadge;
