import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Layout from "src/design-system/layouts/layout";
import ScorecardSurveyList from "src/design-system/blocks/scorecard/scorecard-survey-list/scorecard-survey-list";
import PageNav from "src/design-system/components/primary-navigation/circulytics-page-navigation";

const CirculyticsPage = () => {
  return (
    <Layout>
      <main>
        <title>Scorecards | Circulytics</title>
        <PageNav />
        <h1>Scorecards</h1>
        <p>
          All surveys are displayed here, You can select multiple responses to
          have their scorecards created.
          <br />
          Make sure you check over data in “Review”, and if applicable
          “Benchmarks” before clicking “Scorecards”
          <br />
          <br />
        </p>
        <ScorecardSurveyList />
      </main>
    </Layout>
  );
};

export default withAuthenticator(CirculyticsPage);
