import React from "react";
import styled from "@emotion/styled";

import vars from "src/design-system/variables";
import Checkbox from "src/design-system/atoms/checkbox";

const ScorecardControl = ({ className, onSelection, isSelected }) => {
  return (
    <div className={className}>
      <Checkbox
        onSelection={onSelection}
        isSelected={isSelected}
        color={vars.learningGreen}
      ></Checkbox>
    </div>
  );
};

const StyledScorecardControl = styled(ScorecardControl)`
  display: flex;
  width: 100%;
  margin-left: 1rem;
  button {
    margin-right: 0.25rem;
  }
  ${Checkbox} input {
    width: 50px;
    height: 50px;
  }
`;

export default StyledScorecardControl;
