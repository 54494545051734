import React from "react";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";

const ScorecardSurveyHeader = () => {
  return (
    <DataRow isHeader columnWidths={vars.scorecardSurveyColumnWidths}>
      <div>Review Flags</div>
      <div>Response ID / Circ ID</div>
      <div>Company Name</div>
      <div>Submission Date</div>
      <div>Review Date</div>
      <div>FY / Version</div>
      <div>Overall Score</div>
      <div>Detailed Results</div>
      <div>SDG Mapping</div>
      <div>Final Data</div>
      <div>Scorecard</div>
    </DataRow>
  );
};

export default ScorecardSurveyHeader;
