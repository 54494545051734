import React, { useState, useEffect } from "react";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";
import { formatDate } from "src/utils/dateHelpers";
import FlagBadges from "src/design-system/atoms/flagBadges";
import { LinkButton } from "src/design-system/atoms/button";
import useScorecardDownload from "src/design-system/atoms/useScorecardDownload";
import useS3Download from "src/design-system/atoms/useS3Download";

const ScorecardWholeCompanyDataRow = ({ className, data }) => {
  const flagData = [
    {
      state: data["whole-company"].isDataValid,
      text: "DV",
      fullText: "Data valid",
    },
    {
      state: data.approvedForBenchmarking,
      text: "BM",
      fullText: "Benchmarking",
    },
    {
      state: data.approvedForHistoricalData,
      text: "HD",
      fullText: "Historical data",
    },
    { state: data.approvedForDisclosure, text: "DC", fullText: "Disclosure" },
    {
      state: data.isReviewed === false ? null : data.isReviewed,
      text: "R",
      fullText: "Reviewed",
    },
  ];
  const scorecards = data["whole-company"].scorecardUrls;
  const [isFinalScorecard, setIsFinalScorecard] = useState(false);
  const { hasScorecards, downloadScorecard, latestScorecard } =
    useScorecardDownload(scorecards);
  useEffect(() => {
    if (latestScorecard) {
      setIsFinalScorecard(latestScorecard.includes("Final"));
    }
  }, [setIsFinalScorecard, latestScorecard]);
  const sdgUrl = data["whole-company"].sdgUrl;
  const { hasS3Url: hasSdg, download: downloadSdg } = useS3Download(sdgUrl);
  const detailedResultsUrl = data["whole-company"].detailedResultsUrl;
  const { hasS3Url: hasDetailedResults, download: downloadDetailedResults } =
    useS3Download(detailedResultsUrl);

  return (
    <DataRow
      className={className}
      columnWidths={vars.scorecardSurveyColumnWidths}
    >
      <div>
        <FlagBadges flagData={flagData} />
      </div>
      <div>
        {data.responseId}
        <hr />
        {data.circulyticsId}
      </div>
      <div>{data.companyName}</div>
      <div>{formatDate(data.recordedDate)}</div>
      <div>{formatDate(data.dateOfAnalystReview)}</div>
      <div>
        {data.financialYear} / v{data.circulyticsJsonSchemaVersion.slice(0, 3)}
      </div>
      <div>{data["whole-company"].overall_score}</div>
      <LinkButton
        isDisabled={!hasDetailedResults}
        onClick={downloadDetailedResults}
        href={detailedResultsUrl}
      >
        Download
      </LinkButton>
      <LinkButton isDisabled={!hasSdg} onClick={downloadSdg} href={sdgUrl}>
        Download
      </LinkButton>
      <a
        href={data.serviceOutputData.final_data}
        target="_blank"
        rel="noreferrer"
      >
        View Data
      </a>
      <LinkButton
        isDisabled={!hasScorecards}
        onClick={downloadScorecard}
        href={latestScorecard}
      >
        Download ({isFinalScorecard ? "Final" : "Draft"})
      </LinkButton>
    </DataRow>
  );
};

export default ScorecardWholeCompanyDataRow;
